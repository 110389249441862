import React, { useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import Avatar from "@material-ui/core/Avatar"
import styled from "styled-components"

import { Card } from "../components/shared/card"
import { ResponsiveMapContainer } from "../components/shared/responsive-map"
import { CenteredContent } from "../components/shared/centered-content"
import { Title, Description } from "../components/shared/text"
import { useMobileDetection } from "../hooks/useMobileDetection"
import { colors } from "../theme"

const StyledAvatar = styled.img`
  width: 175px;
  height: 117px;
`

const Row = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: ${colors.tallyGreyInset};
  box-shadow: inset 0 0 6px #000;
  justify-content: space-between;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ isUsingMobile }) =>
    isUsingMobile ? " 0 10px" : "10px 60px 0 0"};
`

const StoreHours = styled.p`
  font-size: 1.2em;
`

const LocationTemplate = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data // data.allMarkdownRemark.nodes holds our menu items

  const { isUsingMobile } = useMobileDetection()

  return (
    <div>
      {nodes.length &&
        nodes.map(({ frontmatter, html, id }) => (
          <CenteredContent key={id}>
            <Card
              color={colors.tallyBlack}
              style={{ minWidth: "95%", height: "auto", marginBottom: "1rem" }}
            >
              <ResponsiveMapContainer
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <Row>
                <StyledAvatar alt={frontmatter.title} src={frontmatter.image} />
                <Stack isUsingMobile={isUsingMobile}>
                  <Title>{frontmatter.title}</Title>
                  <Description>
                    Open from {frontmatter.open} to {frontmatter.close}
                  </Description>
                </Stack>
              </Row>
            </Card>
          </CenteredContent>
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query LocationTemplate {
        allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/location/i" } } }
        ) {
          nodes {
            frontmatter {
              title
              path
              date
              image
              open
              close
            }
            html
            id
          }
        }
      }
    `}
    render={data => <LocationTemplate data={data} />}
  />
)
