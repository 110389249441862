import React from "react"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import StoreMallDirectory from "@material-ui/icons/StoreMallDirectory"

import { MacBackGround } from "../components/shared/background"
import { NavMenuProvider } from "../context/nav-menu"
import { Navigation } from "../components/shared/navigation"
import LocationTemplate from "../templates/locationTemplate"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { StoreLocation, CampusLocation } from "../components/store-location"
import { CenteredContent } from "../components/shared/centered-content"
import { colors } from "../theme"

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

export default () => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  return (
    <NavMenuProvider>
      <MacBackGround>
        <Navigation />
        <CenteredContent>
          <div style={{ backgroundColor: `${colors.tallyGrey}` }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                style={{ backgroundColor: `${colors.tallyBlack}` }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  {...a11yProps(0)}
                  label={
                    <>
                      <StoreMallDirectory />
                      <span>Store Front</span>
                    </>
                  }
                />
                <Tab
                  {...a11yProps(1)}
                  label={
                    <>
                      <LocalShippingIcon />
                      <span>Food Trucks</span>
                    </>
                  }
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <Box p={3}>
                <StoreLocation />
                <CampusLocation />
              </Box>
              <Box p={3}>
                <LocationTemplate />
              </Box>
            </SwipeableViews>
          </div>
        </CenteredContent>
      </MacBackGround>
    </NavMenuProvider>
  )
}
