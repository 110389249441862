import React from "react"
import styled from "styled-components"

import { ResponsiveMapContainer } from "../shared/responsive-map"
import { Card } from "../shared/card"
import { CenteredContent } from "../shared/centered-content"
import { Title, Description } from "../shared/text"
import { colors } from "../../theme"

const Inset = styled.div`
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 15px;
  background-color: ${colors.tallyGreyInset};
  box-shadow: inset 0 0 6px #000;
`

export const StoreLocation = () => (
  <CenteredContent>
    <Card
      color={colors.tallyBlack}
      backgroundColor="#fff"
      style={{ width: "85%", height: "auto", marginBottom: "1rem" }}
    >
      <ResponsiveMapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.154382780894!2d-84.28370298487573!3d30.431725181743083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ecf56126cbbfd1%3A0x9dc3dc4dfff4e367!2sTally%20Mac%20Shack!5e0!3m2!1sen!2sus!4v1570382718089!5m2!1sen!2sus"
          style={{ border: 0, width: "100%", height: 450, frameborder: 0 }}
          allowfullscreen=""
          title="tally mac store front"
        ></iframe>
      </ResponsiveMapContainer>
      <Inset>
        <Title>Adams st. location</Title>
        <Description>Open from 8am to 5pm</Description>
      </Inset>
    </Card>
  </CenteredContent>
)

export const CampusLocation = () => (
  <CenteredContent>
    <Card
      color={colors.tallyBlack}
      backgroundColor="#fff"
      style={{ width: "85%", height: "auto", marginBottom: "1rem" }}
    >
      <ResponsiveMapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.864344451905!2d-84.2988979848755!3d30.439947281740146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ecf50e71ca2ff7%3A0xe486ca72cb7b371!2s824%20W%20Jefferson%20St%2C%20Tallahassee%2C%20FL%2032304!5e0!3m2!1sen!2sus!4v1570583768361!5m2!1sen!2sus"
          style={{ border: 0, width: "100%", height: 450, frameborder: 0 }}
          allowfullscreen=""
          title="campus tally mac store front"
        ></iframe>
      </ResponsiveMapContainer>
      <Inset>
        <Title>FSU Campus Location</Title>
        <Description>Open from 8am to 5pm</Description>
      </Inset>
    </Card>
  </CenteredContent>
)
