import styled from "styled-components"

import { colors } from "../../../theme"

export const Header = styled.h1`
  font-family: "Pacifico", cursive;
  font-size: 2.8em;
  color: ${colors.tallyWhite};
  text-shadow: 3px 4px 15px #000;
  margin-bottom: 5px;
  margin-top: 5px;
`

export const Title = styled.h3`
  color: ${colors.tallyYellow};
`

export const Description = styled.p`
  margin-top: -10px;
  font-size: 1.2em;
  color: ${colors.tallyWhite};
`
